import React from 'react';

const WlFeatures = () => {
  return (
    <section className="hero">
      <h4>DAI, xDAI and 500+ Erc20</h4>
      <h4>Swap Tokens instantly</h4>
      <h4>Get your Ens name for free</h4>
      <h4>Info & Transaction History</h4>
    </section>
  );
};

export default WlFeatures;
